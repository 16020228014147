<template>
  <v-container>
    <v-toolbar flat>
      <v-toolbar-title color="primary">
        <h4 color="primary">
          {{$t('message.shop-list-title')}}
        </h4>
      </v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>

      <!-- routering to create item -->
      <template>
        <router-link to="/createShop">
          <v-btn rounded color="primary">
            <v-icon left>
              mdi-plus
            </v-icon>
            {{$t('message.create')}}
          </v-btn>
        </router-link>
      </template>
    </v-toolbar>
    <!-- data table -->
    <v-pagination
      v-if="!isLength"
      v-model="page"
      :length="lengthPage"
      :total-visible="7"
      @input="changePage"
      depressed
    ></v-pagination>
    <v-data-table
      :headers="headers"
      :items="shopList"
      disable-sort
      :hide-default-footer="true"
      :hide-default-header="true"
      :items-per-page="totalPage.per_page"
      class="elevation-0"
      justify="center"
      :server-items-length="totalPage.total"
      :options.sync="option"
      :footer-props="{
          'items-per-page-options': [(totalPage.per_page != null ? totalPage.per_page : 5)]
      }"
      :page="totalPage.current_page"
    >

    <template v-slot:header="{ props }" style="min-height: 50px">
        <th class="custom-th text-center">
          {{ props.headers[0].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[1].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[2].text }}
        </th>
        <th class="custom-th text-center" style="width: 25%;">
          {{ props.headers[3].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[4].text }}
        </th>
        <th class="custom-th text-center">
          {{ props.headers[5].text }}
        </th>
      </template>
      <!-- imageproduct itemlist loop -->
      <template v-slot:[`item.image_url`]="{ item }">
        <v-img
          :lazy-src="item.image_url"
          :src="item.image_url"
          class="image-url-class"
          style="width:100px; height: auto;"
        ></v-img>
      </template>

      <!-- action button itemlist loop -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="red"
              class="mr-2"
              small
              @click="deleteItem(item.id)">
              mdi-delete
            </v-icon>
          </template>
          <span>{{ $t("message.label-delete-item") }}</span>
        </v-tooltip>
        <!-- <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="indigo"
              v-bind="attrs"
              v-on="on"
              small
              class="mr-2"
              @click="detailShop(item.id)">
              mdi-eye
            </v-icon>
          </template>
          <span>{{ $t("message.label-detail-item") }}</span>
        </v-tooltip> -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="indigo"
              small
              class="mr-2"
              @click="editShop(item.id)">
              mdi-pencil
            </v-icon>
          </template>
          <span>{{ $t("message.label-edit-item") }}</span>
        </v-tooltip>
      </template>

      <template v-slot:top="">
        <!-- dialog delete confirmation -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">{{$t('message.confirmation-delete-message')}}</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click.prevent="cancelDelete">
                {{$t('message.cancel')}}
              </v-btn>
              <v-btn color="blue darken-1" text @click.prevent="deleteItemConfirm">
                {{$t('message.ok')}}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- top pagination extend -->
        <!-- <v-data-footer
          :pagination="pagination"
          :options="options"
          @update:options="updateOptions"
          :items-per-page-text="$t('message.rows-per-page')"
          class="elavation-0 v-data-footer-top"
          disable-items-per-page
        /> -->
      </template>
    </v-data-table>
  </v-container>
</template>

<script>

export default {
  name: "ShopList",
  data: () => ({
    // itemListData: [],
    dialogDelete: false,
    page: 1,
    deletedId: 0,
    option: {},
    message: {}
  }),

  watch: {
    dialogDelete (val) {
      val || this.cancelDelete()
    },
    // option: {
    //   handler() {
    //     this.dispatchShopList()
    //   }, deep: true
    // }
    option: {
      handler() {
        this.page = parseInt(this.$store.state.shop_module.pageState)
      },
      deep: true
    }
  },

  computed: {
     isLength() {
      return this.$store.state.shop_module.is_length;
    },
    lengthPage() {
      return this.$store.state.shop_module.length_page;
    },
    shopList(){
      return this.$store.state.shop_module.shopListResult
    },
    totalPage () {
      return this.$store.state.shop_module.totalPage
    },
    headers() {
      return [
        {
          text: this.$t('message.id'),
          align: "center",
          value: "id",
          width: "10%"
        },
        { text: this.$t('message.shop'), value: "store_name", width: "15%", align: "center"},
        { text: this.$t('message.image'), value: "image_url", align: "center", width: "13%"},
        { text: this.$t('message.number-of-product'), value: "item_total", align: "center", width: "10%"},
        { text: this.$t('message.open-day'), value: "open_day", align: "center", width: "8%" },
        // { text: this.$t('message.status'), value: "is_active", align: "center", width: "10%" },
        { text: this.$t('message.action'), value: "actions", align: "center", width: "12%"},
      ]
    }

  },
  methods: {
    changePage(event) {
      return this.$store.dispatch("shop_module/fetchShopList", {
        page: event,
      });
    },
    dispatchShopList(){
      // let {page} = this.option
      let setPage = parseInt(this.$store.state.shop_module.pageState)
      this.page = parseInt(this.$store.state.shop_module.pageState)
      return this.$store.dispatch('shop_module/fetchShopList', { 'page': setPage})
    },
    deleteItem(paramsId) {
      this.deletedId = paramsId
      this.dialogDelete = true
    },
    cancelDelete() {
      this.dialogDelete = false
    },
    deleteItemConfirm(paramsId) {
      this.message.title = this.$t("message.title-deleteShop-success-message")
      this.$store.dispatch('shop_module/deleteShop', {
        payload: this.deletedId,
        message: {
          title: this.message.title
        }
      })
      this.dialogDelete = false
    },
    editShop(paramsId) {
      this.$router.push(`/editShop/${paramsId}`)
    },
    detailShop(paramsId) {
      this.$router.push(`/shopDetail/${paramsId}`)
    }
  },

  created() {
    this.dispatchShopList()
  },
};
</script>

<style scoped>
.custom-th {
  background: #3949ab !important;
  padding: 12px;
  color: white;
}
.image-url-class {
  margin: 10px auto !important
}
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}

thead tr th {
  color: white !important;
  font-size: 15px !important;
}
.v-application .text-start,
tr td {
  text-align: start !important;
  padding-left: 10px !important;
  padding-right: 0px !important;
}
tbody tr:hover:not(.v-table__expanded__content) {
  background: transparent !important;
}
.v-data-footer-top {
  border-top: none !important;
}
</style>
